export const CHANGE_BG_COLOR = "CHANGE_BG_COLOR";
export const LOGOUT_STATE = "LOGOUT_STATE";
export const PAGINATION_PAGE_NUMBER = "PAGINATION_PAGE_NUMBER";
export const PAGINATION_REFRESH = "PAGINATION_REFRESH";
export const SINGLE_MESSAGE = "SINGLE_MESSAGE";
export const EDIT_USER_ID = "EDIT_USER_ID";



export const USER_STATE = "USER_STATE";   
export const CHAT_BOX_STATE = "CHAT_BOX_STATE";   
export const CHAT_BOX_TOGGLE_STATE = "CHAT_BOX_TOGGLE_STATE";   
export const ALT_SIDEBAR_STATE = "ALT_SIDEBAR_STATE";   
export const SIDEBAR_STATE = "SIDEBAR_STATE";   
export const ALT_BAR_CONTACT_LOADING_STATE = "ALT_BAR_CONTACT_LOADING_STATE";   
export const RECIEVER_ID_ACTION = "RECIEVER_ID_ACTION";
export const POST_IMAGE_STATE = "POST_IMAGE_STATE";
export const POST_SINGLE_IMAGE_STATE = "POST_SINGLE_IMAGE_STATE";
export const POST_REFRESH = "POST_REFRESH";
export const POST_EDIT_VIEW_STATE = "POST_EDIT_VIEW_STATE";
export const COMMENT_VIEW_STATE = "COMMENT_VIEW_STATE";
export const INDUSTRY_VIEW_STATE = "INDUSTRY_VIEW_STATE";
export const SERVICE_VIEW_STATE = "SERVICE_VIEW_STATE";
export const PRODUCT_VIEW_STATE = "PRODUCT_VIEW_STATE";
export const TEAM_MEMBER_VIEW_STATE = "TEAM_MEMBER_VIEW_STATE";
export const CONTACT_ADDRESS_VIEW_STATE = "CONTACT_ADDRESS_VIEW_STATE";